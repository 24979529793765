<template>
  <v-card>
    <v-card-title>
      Cashout Paypal
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-container>
      <v-alert
        :value="showExportMessage"
        colored-border
        dismissible
        color="primary lighten-1"
        border="top"
        icon="mdi-check"
        transition="scale-transition"
        elevation="2"
        prominent
      >
        🤾🏻‍♀️ 🤸🏻‍♂️ 🏌🏻‍♀️ 🤾🏻‍♂️ Your export is currently processing. Once the export is
        completed 🏁, you will receive a notification via Slack under
        <code>#admindashboard</code> channel 📢.
      </v-alert>

      <v-btn color="primary" class="ml-3" @click="exportCashoutPaypal"
        >Export</v-btn
      >
    </v-container>

    <v-data-table
      :headers="headers"
      :items="cashouts"
      class="elevation-1"
      :search="search"
    >
      <!-- <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="$router.push({ path: `/mobile/reward/${item.rewardId}/edit`, params: item })">visibility</v-icon>
        <v-icon small class="mr-2" @click="$router.push({ path: `/mobile/cashout/${item._id}/edit`, params: item })">edit</v-icon>
      </template> -->
    </v-data-table>
  </v-card>
</template>


<script>
import RestResource from "@/services/dataServiceMobile.js";

const service = new RestResource();
export default {
  data() {
    return {
      cashouts: [],
      search: "",
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "Country", value: "country", sortable: true },
        { text: "Currency", value: "currency", sortable: true },
        { text: "Amount", value: "amountInCurrency", sortable: false },
        { text: "Credited", value: "credited", sortable: true },
        { text: "Created At", value: "createdAt", sortable: false },
        // { text: "Action", value: "actions", sortable: false },
      ],
      showExportMessage: false,
    };
  },

  mounted() {
    this.fetchCashout();
  },

  methods: {
    fetchCashout() {
      this.$setLoader();
      service.fetchCashoutPaypal().then((res) => {
        this.cashouts = res.data;
        this.$disableLoader();
      });
    },

    exportCashoutPaypal() {
      service.exportCashoutPaypal().then(() => {
        this.showExportMessage = true;
      });
    },
  },
};
</script>